.button-container {
  display: inline-block;
}

.button-hint {
  font-size: 10px;
  margin-left: 2px;
  padding: 0;
  position: absolute;
}

.image-hint {
  z-index: 2;
  background-color: black;
  color: white;
  font-weight: bold;
  font-size: 10px;
  margin-left: 2px;
  border-radius: 5px;
  padding: 2px 4px 2px 4px;
  position: absolute;
}

.selected-image {
  z-index: 1;
  -webkit-transition-duration: -webkit-transform 0.5s;
  transition-duration: 0.5s;
  opacity: 0.5;
}

.image {
  z-index: 1;
  -webkit-transition-duration: -webkit-transform 0.5s;
  transition-duration: 0.5s;
  opacity: 1;
}

.batch-container {
  width: 72vh;
  height: 72vh;
}

.batch-image {
  max-width: 24vh;
  max-height: 24vh;
}

.batch-image-container {
  padding: 0;
}
