.container {
  display: inline-block;
  cursor: pointer;
}

.hint {
  font-size: 10px;
  margin-left: 2px;
  padding: 0;
  position: absolute;
}
