.batch-image {
  max-height: 50vh;
  max-width: 100%;
}

.task-container {
  display: grid;
  justify-content: center;
  gap: 10px;
  padding: 10px;
}

.buttons-container {
  display: flex;
  gap: 10px;
  justify-self: center;
  max-width: 90vw;
  flex-wrap: wrap;
}

.buttons-container > * {
  cursor: pointer;
}

.images-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  max-width: 90vw;
  flex-wrap: wrap;
}
