.threshold-input {
  width: 100%;
}

.multiple-select {
  width: 250px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.date-picker {
  position: absolute;
}
