.container {
  padding: 20px;
}

.navigation {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.home-btn {
  justify-self: flex-end;
  padding: 10px;
}
